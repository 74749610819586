import { WithChildren } from 'types/WithChildren';
import { ILayout } from '../interfaces/ILayout';

import { GlobalStyle } from './styles/global';

export const Layout: WithChildren<ILayout> = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <main id="main-element">{children}</main>
    </>
  );
};

export default Layout;
