import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		outline: none;
		box-sizing: border-box;
    letter-spacing: 1px;
	}

  html {
    font-size: 10px;
    font-family: 'Montserrat', sans-serif;
    color: ${(p) => p.theme.colors.textDark};
  }

  body {
    overflow: hidden;
  }

  svg {
    stroke-width: 1.5px;
  }

  #__next {
    overflow-x: hidden;
  }

  main {
    height: 100vh;
    background-color: ${(p) => p.theme.colors.background};
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  input, button, textarea {
    font-family: 'Montserrat', sans-serif;
  }

  textarea {
    min-height: 136px;
  }

  ::placeholder {
    color: ${(p) => p.theme.colors.placeholder};
  }

  button {
    border: none;
    background: none;
  }

  a {
    text-decoration: none;
    color: ${(p) => p.theme.colors.textDark};
  }

  #nprogress .bar {
    background: ${({ theme }) => theme.colors.primary} !important;
    height: 3px;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px ${({ theme }) => theme.colors.primary},
                0 0 5px ${({ theme }) => theme.colors.primary};
  }

  .__jivoMobileButton > *:first-child {
    width: 48px !important;
    height: 48px !important;
    margin-right: ${({ theme }) => theme.spacing.large} !important;

    .icons_4f28 {
      max-width: ${(p) => p.theme.font.xLarge} !important;
      height: ${(p) => p.theme.font.xLarge} !important;
      background-size: contain !important;
    }
  }

  @media(min-width: 769px) {
    [data-title]:not(.tooltip--off) {
      position: relative;

      ::after {
        font-size: 1.1rem;
        content: attr(data-title);
        position: absolute;
        top: calc(100% + 12.5px);
        left: 50%;
        transform: translateX(-50%) translateY(-5px);
        background-color: #717171;
        color: #fff;
        padding: 5px 7.5px;
        white-space: nowrap;
        border-radius: 4px;
        visibility: hidden;
        opacity: 0;
        z-index: 9999999;

        transition: visibility 0.18s, opacity 0.18s, transform 0.18s;
        will-change: transition;

        pointer-events: none;
      }

      :hover::after {
        visibility: visible;
        opacity: 1;
        transform: translateX(-50%);
      }

      ::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        background-color: transparent;
        border-left: 7.5px solid transparent;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid #717171;
        border-right: 7.5px solid transparent;
        z-index: 9999999;

        transform: translateX(-50%) translateY(-5px);
        visibility: hidden;
        opacity: 0;

        transition: visibility 0.18s, opacity 0.18s, transform 0.18s;
        will-change: transition;
      }

      :hover::before {
        visibility: visible;
        opacity: 1;
        transform: translateX(-50%);
      }
    }
  }


  ::-webkit-scrollbar {
    width: ${(p) => p.theme.spacing.nano};
    height: ${(p) => p.theme.spacing.nano};
    background: ${(p) => p.theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.colors.primary};
  }
  ::-webkit-scrollbar-track {
    background-color: ${(p) => p.theme.colors.border};
  }


  @keyframes skeleton {
    0% {
      background-position: -100%;
    }
    100% {
      background-position: 200%;
    }
  }

  @media (max-width: 768px) {
    * {
      cursor: default;
    }
  }
`;
